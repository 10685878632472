import React from "react";
import Breadcrumb from "../../atoms/breadcrumb/breadcrumb";
import FormContact from "../form-contact/formContact";
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { BLOCKS } from '@contentful/rich-text-types';
import Map from "../map/map";
import { ReferenciaContenido } from "../../../interfaces/ReferenciaContenido";
import { OptimizeImage } from "../../../shared/utils/stringUtilities";
import "./bodyContact.scss";

interface IBodyContact {
   nodes: {
      tituloPrincipal: string;
      descripcion: { json: any };
      tituloFormulario: string;
      descripcionFormulario: { json:any };
      tituloUbicacion: string;
      tituloBoton: string;
      redireccionBoton: string;
      referenciaUbicaciones:Array <{
         titulo:string;
         tituloUbicacion:string;
         localizacion: {
            lat: number,
            lon: number
         }
      }>;
      referenciaPreguntasFrecuentes: Array<ReferenciaContenido>;
      titulosFormulario: { json: any };
      imagenFormulario: any;
   };
}


const Text = (children:any, classname:string) => (
   <p className={classname}>{children}</p>
)

const Span = (children:any) => (<span>{children}</span>)

const options_subtitle = {
   renderNode: {
      [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Text(children, 'o-contact__header-subtitle')
   },
};

const options_message = {
   renderNode: {
      [BLOCKS.PARAGRAPH]: (_node:any, children:any) => Span(children)
   },
};

const BodyContact = (props:IBodyContact) => {
   return (
      <div className="o-contact">
         <Breadcrumb content={`Inicio / ${props.nodes[0].tituloPrincipal}`} />
         <div className={`o-contact__header`}>
            <h1 className="o-contact__header-title title-primary">
               {props.nodes[0].tituloPrincipal}
            </h1>
            {props.nodes[0].descripcion.json.content && documentToReactComponents(props.nodes[0].descripcion.json, options_subtitle)}
            <p className="o-contact__header-message">
               {props.nodes[0].tituloFormulario}
               {props.nodes[0].descripcionFormulario.json.content && documentToReactComponents(props.nodes[0].descripcionFormulario.json, options_message)}
            </p>
         </div>
         <div className={`o-contact__cont`}>
            <div className="o-contact__cont-form">
               <FormContact {...props.nodes[0].titulosFormulario}/> 
            </div>
            <div className={"o-contact__cont-img hide-on-mobile"}>
               <img loading="lazy" 
                  src={props.nodes[0].imagenFormulario && 
                     OptimizeImage(props.nodes[0].imagenFormulario.file.url, props.nodes[0].imagenFormulario.file.contentType, 768)
                  } alt="Imagen contacto"/>
            </div>
         </div>
         <Map
            tituloUbicacion={props.nodes[0].tituloUbicacion} 
            tituloBoton={props.nodes[0].tituloBoton}
            redireccionBoton={props.nodes[0].redireccionBoton}
            referenciaUbicaciones={props.nodes[0].referenciaUbicaciones} 
         />
      </div> 
   );
};

export default BodyContact;
