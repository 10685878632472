import React, { useState, useEffect, useRef } from "react"
import GenericToast from "../../atoms/generic-toast/genericToast"
import "./inputFilesZip.scss"

const InputFilesZip = (props: InputFilesZipInterface) => {

   const { title, nameId, handleChange } = props;

   const [currentSize, setCurrentSize] = useState<any>(0)

   let inputRef = useRef(null);
   let validTypes = ["png", "jpg", "jpeg", "pdf", "doc", "xls", "docx"]
   const [filesArr, setFilesArr] = useState<any>([]);
   
   const toastMsgs = {
      default: "",
      errorFileSize: "El archivo debe pesar menos de 8MB",
      errorFileType: "El archivo debe tener el formato adecuado",
      successDelete: "El archivo se eliminó exitosamente"
   }
   const [toastMsg, setToastMsg] = useState<any>(toastMsgs.default);

   const verifyType = (file: any): boolean => {
      if (validTypes) {
         for (let type of validTypes) {
            if ((type === "image" && file.type.includes("image")) ||
               (type === "png" && file.type.includes("png")) ||
               (type === "jpg" && file.type.includes("jpg")) ||
               (type === "jpeg" && file.type.includes("jpeg")) ||
               (type === "pdf" && file.type.includes("pdf")) ||
               (type === "doc" && file.name.includes("doc")) ||
               (type === "docx" && file.name.includes("docx")) ||
               (type === "xls" && file.name.includes("xls"))) {
               return true
            }
         }
      } else if (file.type.includes("image") ||
         file.type.includes("pdf") ||
         file.name.includes("doc") ||
         file.name.includes("docx") ||
         file.name.includes("xls")) {
         return true
      }
      return false
   }

   useEffect(() => {
      handleChange(filesArr, nameId);
   }, [filesArr]);

   const encodeFileBase64 = (file) => {
      let reader = new FileReader()
      if (file) {
         reader.readAsDataURL(file)
         reader.onload = () => {
            let base64: any = reader.result
            if (base64) {
               setFilesArr(
               filesArr.concat([
                  {
                     base64: base64.split(",")[1],
                     name: file.name,
                     size: file.size,
                  },
               ])
               )
               setCurrentSize(currentSize + file.size)
               setToastMsg(
                  <GenericToast
                     setActiveToast={setToastMsg}
                     isOk={true}
                     message={"Tu archivo se subió correctamente"}
                     position="up-left"
                     duration={4000}
                  />
               )
            }
         }
         reader.onerror = (e) => {
            console.log("Error: ", e)
         }
      }
   }

   const handleFileChange = (e) => {
      const files: FileList = e.target.files
      Array.from(files).forEach((file) => {
         let newsize = currentSize + file.size
         if (newsize < 8000000) {
            if (verifyType(file)) {
               encodeFileBase64(file)
            } else {
               e.target.value = ""
               setToastMsg(
               <GenericToast
                  setActiveToast={setToastMsg}
                  isOk={false}
                  message={toastMsgs.errorFileType}
                  position="up-left"
                  duration={4000}
               />
               )
            }
         } else {
            setToastMsg(
               <GenericToast
                  setActiveToast={setToastMsg}
                  isOk={false}
                  message={toastMsgs.errorFileSize}
                  position="up-left"
                  duration={4000}
               />
            )
         }
      })
   }

   const handleRemoveFile = (fileName: any) => {
      if (filesArr) {
      let deleteSize = filesArr.filter((item) => item.name === fileName)[0]
         .size
      setCurrentSize(currentSize - deleteSize)
      setFilesArr(filesArr.filter((item) => item.name != fileName))
      }
      setToastMsg(
         <GenericToast
            setActiveToast={setToastMsg}
            isOk={true}
            message={toastMsgs.successDelete}
            position="up-left"
            duration={4000}
         />
      )
   }

   return (
      <div className="a-input-files-zip">
         {
            filesArr && currentSize < 8000000 && (
            <>
               <label htmlFor="avatar" className="a-input-files-zip__title">
                  {title}
               </label>
               <div className="a-input-files-zip__input">
                  <div>
                     <i className="icon-file1"></i>
                     <input
                        onChange={handleFileChange}
                        type="file"
                        id="showFiles"
                        name="showFiles"
                        ref={inputRef}
                     />
                  </div>
                  <span>Máximo 8MB</span>
               </div>
            </>
         )}

         <div className="a-input-files-zip__files">
            <p className="a-input-files-zip__files-title">Archivos subidos </p>
            <ul className="a-input-files-zip__files-list">
               {filesArr.map((item, i) => (
                  <li key={`item${i}`}>
                     <p> {item.name} </p>
                     <button
                        onClick={e => { e.preventDefault(); handleRemoveFile(item.name); }}>
                        <i className="icon-trash-alt"></i>
                     </button>
                  </li>
               ))}
            </ul>
         </div>

         {toastMsg && <>{toastMsg}</>}

      </div>
   )
}

interface InputFilesZipInterface {
   title?: string,
   nameId: string,
   value: any,
   handleChange: (value: any, name: string) => any,
   maxFiles?: number
}

export default InputFilesZip
