import React, { useEffect, useState, useRef, MutableRefObject } from "react";
import "./input.scss";
interface IInput {
   title: string;
   value: string;
   nameId: string;
   placeholder?: string;
   type:
      | "select"
      | "text"
      | "text-area"
      | "number"
      | "predict"
      | "date"
      | "select-key-value";
   options?: Array<any>;
   isError?: boolean;
   isReferencePoint?: boolean;
   errorMessage?: string;
   setCodeType?: any;
   setCodeTheme?: any;
   setCodeTypeDoc?: any;
   setCodeTypeGender?: any;
   setCodeEtn?: any;
   setMunicipality?: any;
   setComune?: any;
   setAnnouncement?: any;
   gigyaAccount?: any;
   editable?: boolean;
   person?: string;
   firstTime?: boolean;
   handleChange: (value: any, name?: string) => void;
   tabIndex?: number;
   isSubsideEatFirst?: boolean;
}
const Input = (props: IInput) => {
   const node: MutableRefObject<any> = useRef();
   const {
      value,
      nameId,
      placeholder,
      type,
      isError,
      isReferencePoint,
      errorMessage,
      setCodeType,
      setCodeTheme,
      setCodeTypeDoc,
      gigyaAccount,
      editable,
      firstTime,
      isSubsideEatFirst,
      handleChange,
      title
   } = props;
   const [isActive, setActiveSelect] = useState(false);
   const [isPredict, setPredict] = useState(false);
   const [selectedLabel, setSelectedLabel] = useState(placeholder);
   const [keyCode, setkeyCode] = useState(false);
   const options = ( props.person && props.person === 'person' && nameId === 'idNumType' ) ? props.options?.slice(0, -1) : props.options
   const setActiveOption = (option, select) => {
      setSelectedLabel(option);
      handleChange(option, select);
   };
   const handleClickOutside = (e) => {
      if (node.current.contains(e.target)) {
         return;
      } else {
         setActiveSelect(false);
      }
   };
   const handleSpan = (option, select, e?) => {
      if (e) {
         e.stopPropagation();
      }
      setActiveSelect(false);
      setActiveOption(option, select);
      if (nameId === "request") setCodeType(select);
      if (nameId === "subject") setCodeTheme(select);
      if (nameId === "idNumType") setCodeTypeDoc(select);
   };

   useEffect(() => {
      if (!value) {
         setSelectedLabel("");
      }
   }, [value]);

   useEffect(() => {
      if (props.person && options && nameId === "idNumType") {
         if (props.person === 'company') {
            handleSpan(options[options.length - 1].split(",")[0], options[options.length - 1].split(",")[1])
         } else {
            handleSpan(options[0].split(",")[0], options[0].split(",")[1])
         }
      }
   }, [props.person]);

   const filterOptions = () => {
      if (value === "") {
         return options?.sort();
      } else if (options?.includes(value)) {
         return options.sort();
      } else {
         let filterResult = options?.filter((option) =>
            option.toLowerCase().includes(value.toLocaleLowerCase())
         );
         return filterResult?.sort();
      }
   };
   const getToday = () => {
      const date = new Date();
      const hoy = date.toLocaleDateString();
      const fh = hoy.split("/");
      const day = fh[0];
      const month = fh[1];
      const year = fh[2];
      const m = parseInt(month);
      const today = `${year}-${parseInt(day) < 10 ? 0 + day : day}-${
         m < 10 ? "0" + month : m
      }`;
      return today;
   };
   const validateNumber = (event) => {
      if (
         event.target.value <= 10000000000 &&
         event.target.name !== "phone" &&
         !keyCode
      ) {
         handleChange(event.target.value, nameId);
      }
      if (
         event.target.value <= 10000000 &&
         event.target.name === "phone" &&
         !keyCode
      ) {
         handleChange(event.target.value, nameId);
      }
   };
   const validateCharacter = (event, type) => {
      event = event || window.event;
      let expreReg = /[a-zñ\s]+$/gi;
      switch (type) {
         case "idNum":
            expreReg = /[\d]+$/;
            break;
         case "email":
            expreReg = /[\w\d-._@]+$/;
            break;
         case "address":
            expreReg = /[\w\d-#\sñ]+$/gi;
            break;
         case "message":
            expreReg = /[\w\d.,:;ñÑ\s"]+$/;
            break;
            case "complementAddress":
            expreReg = /[\w\d.,:;ñÑ\s"]+$/;
            break;
         case "number":
            expreReg = /[0-9]+$/;
            break;
         case "firstName":
            if (props.person && props.person === 'company') expreReg = /[A-Za-z0-9_-]*$/;
            break;
      }
      var charCode =
         typeof event.which == "undefined" ? event.keyCode : event.which;
      var charStr = String.fromCharCode(charCode);
      if (!charStr.match(expreReg)) event.preventDefault();
   };
   const validateCharacterPaste = (event, type) => {
      let cadenaChar: string;
      event = event || window.event;
      cadenaChar = event.clipboardData.getData("text");
      event.preventDefault();
      let expreReg = /[^a-zñ\s\u00C0-\u017F]/gi;
      switch (type) {
         case "idNum":
            expreReg = /[^\d]/g;
            break;
         case "email":
            expreReg = /[^\w\d-._@]/g;
            break;
         case "address":
            expreReg = /[^\w\d-#\sñÑ\u00C0-\u017F]/g;
            break;
         case "message":
            expreReg = /[^\w\d.,:;ñÑ\s\u00C0-\u017F"]/g;
            break;
         case "number":
            expreReg = /[^0-9]/g;
            break;
      }
      cadenaChar = cadenaChar.normalize("NFD").replace(expreReg, "");
      handleChange(value + cadenaChar, type);
   };
   useEffect(() => {
      if (isActive) {
         document.addEventListener("mousedown", handleClickOutside);
      } else {
         document.removeEventListener("mousedown", handleClickOutside);
      }
      return () => {
         document.removeEventListener("mousedown", handleClickOutside);
      };
   }, [isActive]);

   const inputTypeComponent = () => {
      switch (type) {
         case "select":
            return (
               <button
                  type="button"
                  className={`a-input__field a-input__field--select${
                     isActive ? " active" : ""
                  }${isError ? " a-input__field--error" : ""}`}
                  ref={node}
                  aria-label={title}
                  onClick={() => {
                     if (
                        (isReferencePoint &&
                           gigyaAccount &&
                           nameId === "idNumType") ||
                        (isReferencePoint && nameId === "crossLetter1") ||
                        (isReferencePoint && nameId === "cardinalPoint1") ||
                        (isReferencePoint && nameId === "crossLetter2") ||
                        (isReferencePoint && nameId === "cardinalPoint2") ||
                        (gigyaAccount && nameId === "gender") ||
                        (gigyaAccount &&
                           firstTime === false &&
                           nameId === "ethnicity") ||
                        (gigyaAccount &&
                           firstTime === false &&
                           nameId === "announcement")
                     ) {
                        setActiveSelect(false);
                     } else {
                        setActiveSelect(!isActive);
                     }
                  }}
               >
                  <label className="a-input__field__label" id={nameId}>
                     {(isReferencePoint && nameId === "crossLetter1") ||
                     (isReferencePoint && nameId === "cardinalPoint1") ||
                     (isReferencePoint && nameId === "crossLetter2") ||
                     (isReferencePoint && nameId === "cardinalPoint2")
                        ? ""
                        : selectedLabel}
                  </label>
                  {isActive && (
                     <div className="a-input__field__items">
                        {
                           (options || []).map((option, index) => {
                              return (
                                 <button
                                    aria-label={option.split(",")[0]}
                                    className="a-input__field__items-option"
                                    key={index}
                                    onClick={(e) =>
                                       handleSpan(
                                          option.split(",")[0],
                                          option.split(",")[1],
                                          e
                                       )
                                    }
                                 >
                                    {option.split(",")[0]}
                                 </button>
                              );
                           })
                        }
                     </div>
                  )}
               </button>
            );
         case "select-key-value":
            return (
               <div
                  className={`a-input__field a-input__field--select${
                     isActive ? " active" : ""
                  }${isError ? " a-input__field--error" : ""}`}
                  ref={node}
                  
                  onClick={() => {
                     if (
                        (isReferencePoint &&
                           gigyaAccount &&
                           nameId === "idNumType") ||
                        nameId === "" ||
                        (isReferencePoint && nameId === "crossLetter1") ||
                        (isReferencePoint && nameId === "cardinalPoint1") ||
                        (isReferencePoint && nameId === "crossLetter2") ||
                        (isReferencePoint && nameId === "cardinalPoint2") ||
                        (gigyaAccount && nameId === "gender") ||
                        (gigyaAccount &&
                           firstTime === false &&
                           nameId === "ethnicity") ||
                        (gigyaAccount &&
                           firstTime === false &&
                           nameId === "announcement")
                     ) {
                        setActiveSelect(false);
                     } else {
                        setActiveSelect(!isActive);
                     }
                  }}
               >
                  <label className="a-input__field__label" id={nameId}>
                     {(isReferencePoint && nameId === "crossLetter1") ||
                     (isReferencePoint && nameId === "cardinalPoint1") ||
                     (isReferencePoint && nameId === "crossLetter2") ||
                     (isReferencePoint && nameId === "cardinalPoint2")
                        ? ""
                        : selectedLabel}
                  </label>
                  {isActive && (
                     <ul className="a-input__field__items">
                        {(options || []).map((option) => {
                           return (
                              <li
                                 className="a-input__field__items-option"
                                 key={option.id}
                                 onClick={(e) =>
                                    handleSpan(option.name, option.id, e)
                                 }
                              >
                                 {option.name}
                              </li>
                           );
                        })}
                     </ul>
                  )}
               </div>
            );
         case "text":
            return (
               <input
                  className={`a-input__field${
                     isError ? " a-input__field--error" : ""
                  }`}
                  id={nameId}
                  name={nameId}
                  type={nameId === "email" ? "email" : type}
                  placeholder={placeholder}
                  value={value}
                  disabled={isSubsideEatFirst || ( gigyaAccount && !editable ) ? 
                        nameId === "firstName" ||
                        nameId === "idNumType" ||
                        nameId === "lastName" ||
                        nameId === "email" ||
                        nameId === "idNum" ||
                        nameId === "secondName" ||
                        nameId === "secondSurname"
                     : false
                  }
                  maxLength={nameId === "idNum" ? 20 : 40}
                  onKeyPress={(event) => validateCharacter(event, nameId)}
                  onPasteCapture={(event) =>
                     validateCharacterPaste(event, nameId)
                  }
                  onChange={(event) => {
                     handleChange(event.target.value, nameId);
                  }}
               />
            );
         case "number":
            return (
               <input
                  className={`a-input__field${
                     isError ? " a-input__field--error" : ""
                  }`}
                  id={nameId}
                  name={nameId}
                  type={type}
                  placeholder={placeholder}
                  min={0}
                  value={
                     (isReferencePoint && nameId === "number1") ||
                     (isReferencePoint && nameId === "number2") ||
                     (isReferencePoint && nameId === "number3")
                        ? ""
                        : value
                  }
                  onKeyDown={(e) =>
                     e.keyCode === 69 ? setkeyCode(true) : setkeyCode(false)
                  }
                  disabled={
                     nameId === "idNum" ||
                          (isReferencePoint && nameId === "number1") ||
                          (isReferencePoint && nameId === "crossLetter1") ||
                          (isReferencePoint && nameId === "cardinalPoint1") ||
                          (isReferencePoint && nameId === "number2") ||
                          (isReferencePoint && nameId === "crossLetter2") ||
                          (isReferencePoint && nameId === "cardinalPoint2") ||
                          (isReferencePoint && nameId === "number3")
                        && false
                  }
                  onKeyPress={(event) => validateCharacter(event, "number")}
                  onPasteCapture={(event) =>
                     validateCharacterPaste(event, "number")
                  }
                  onChange={(event) => {
                     validateNumber(event);
                  }}
               />
            );
         case "text-area":
            return (
               <textarea
                  className="a-input__field"
                  rows={5}
                  cols={50}
                  maxLength={800}
                  id={nameId}
                  name={nameId}
                  value={value}
                  onKeyPress={(event) => validateCharacter(event, nameId)}
                  onPasteCapture={(event) =>
                     validateCharacterPaste(event, nameId)
                  }
                  onChange={(event) => handleChange(event.target.value, nameId)}
               />
            );
         case "predict":
            return (
               <>
                  <div className="a-input-predict__content">
                     <input
                        className={`a-input-predict__content__predict a-input__field${
                           isError ? " a-input__field--error" : ""
                        }`}
                        id={nameId}
                        name={nameId}
                        type={type}
                        placeholder={
                           "Selecciona un valor o escribe para encontrarlo"
                        }
                        value={value}
                        onClick={() => {
                           gigyaAccount && nameId === "expeditionPlace"
                              ? setPredict(false)
                              : setPredict(true);
                           setPredict(true);
                        }}
                        onKeyPress={(event) => validateCharacter(event, nameId)}
                        onPaste={(event) => event.preventDefault()}
                        onChange={(event) => {
                           handleChange(event.target.value, nameId);
                        }}
                        autoComplete="off"
                     />
                     <i
                        className={
                           "icon icon-chevron-down1 " +
                           (isPredict ? "icon-top" : "")
                        }
                        onClick={() => setPredict(!isPredict)}
                     />
                  </div>
                  {isPredict && (
                     <div
                        className="a-input__predict"
                        onBlur={() => setPredict(false)}
                     >
                        <ul>
                           {(filterOptions() || []).map((el, index) => (
                              <li
                                 key={`listSpecialist${index}`}
                                 onClick={() => {
                                    handleChange(el, nameId);
                                    setPredict(false);
                                 }}
                              >
                                 {el}
                              </li>
                           ))}
                        </ul>
                     </div>
                  )}
               </>
            );
         case "date":
            return (
               <input
                  className={`a-input__field${
                     isError ? " a-input__field--error" : ""
                  }`}
                  id={nameId}
                  name={nameId}
                  type={nameId === "email" ? "email" : type}
                  value={value}
                  disabled={
                     gigyaAccount && nameId === "birthDate" ? true : false
                  }
                  onChange={(event) => handleChange(event.target.value, nameId)}
                  max={getToday()}
               />
            );
      }
   };
   return (
      <div className={`a-input a-input-${type}`}>
         {inputTypeComponent()}
         {isError && (
            <span className="a-input__title__error">{errorMessage}</span>
         )}
      </div>
   );
};
export default Input;
