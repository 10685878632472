import React from "react";
import Layout from "../components/organisms/layout/layout";
import SEO from "../shared/seo";
import BodyContact from "../components/organisms/body-contact/bodyContact";
import { useStaticQuery, graphql } from "gatsby";

const ContactPage = () => {

   const data = useStaticQuery(graphql`
   query CONTACT_CONTENT {
      allContentfulContactenos(filter: {titulo: {eq: "Información Contáctenos"}}) {
        nodes {
          metaTitulo
          metaDescripcion {
            json
          }
          tituloPrincipal
          descripcion {
            json
          }
          tituloFormulario
          descripcionFormulario {
            json
          }
          tituloUbicacion
          tituloBoton
          redireccionBoton
          referenciaUbicaciones {
            titulo
            tituloUbicacion
            localizacion {
              lat
              lon
            }
          }
          imagenFormulario {
            title
            file {
              url
            }
            fluid(maxWidth: 960, quality: 75) {
              src
              srcSet
              base64
              aspectRatio
              sizes
            }
          }
          titulosFormulario {
            tituloGeneral
            tituloBoton
            redireccionBoton
            referenciaFormulario {
              tipoCampo
              inputPrincipal
              mensajeError
              listaSelect
              nombreId
              tituloBotonTerminos
              textoTerminos {
                json
              }
            }
          }
         }
      }
    }
   `);
   return (
      <Layout hideHeaderDesktop={true} hideHeaderMobile={true} isNotRoot={true}>
         <SEO title={data.allContentfulContactenos.nodes[0].metaTitulo || "Contactanos "} 
         description={data.allContentfulContactenos.nodes[0].metaDescripcion && data.allContentfulContactenos.nodes[0].metaDescripcion.json.content[0].content[0].value }
         noIndex
         />
         <BodyContact {...data.allContentfulContactenos} />
      </Layout>
   );
};

export default ContactPage;
