import React, { useCallback, useEffect, useRef } from "react"
import dynamicClass from "clsx"
import "./genericToast.scss"

interface GenericToastInterface {
  isOk?: boolean,
  message?: string,
  duration?: number,
  position?: string,
  size?: string,
  className?: string,
  setActiveToast: (boolean) => void
}

const GenericToast = ({
  isOk,
  message,
  duration,
  position,
  size,
  setActiveToast,
  className,
}: GenericToastInterface) => {
  const toastContainer = useRef<HTMLDivElement>(null)
  useEffect(() => {
    const timer = setTimeout(
      function () {
        closeToast()
      },
      duration ? duration : 8000
    )
    return () => clearTimeout(timer)
  }, [])

  const closeToast = useCallback(() => {
    if (toastContainer.current) {
      toastContainer.current.classList.add("__hidden")
      setTimeout(function () {
        {
          toastContainer && toastContainer.current?.classList.remove("__hidden")
          setActiveToast && setActiveToast(false)
        }
      }, 200)
    } else {
      setActiveToast && setActiveToast(false)
    }
  }, [])

  const classToast = dynamicClass("a-generic-toast", {
    [`--${position}`]: position,
    "--up": !position,
    [`--${size}`]: size,
    "--big": !size,
    [`${className}`]: className,
  })

  const messageToastValidator = isOk
    ? "Realizado con exito"
    : "Lo sentimos, ocurrió un error. Por favor inténtalo más tarde."

  return (
    <div className={classToast} id="a-generic-toast" ref={toastContainer}>
      <div className="a-generic-toast__content">
        {isOk ? (
            <i className="icon-check-circle1" />
          ) : (
            <i className="icon-exclamation-circle1" />
        )}
        <p>{message ? message : messageToastValidator}</p>
        <button onClick={closeToast} aria-label='close'>
          <i className="icon-close" />
        </button>
      </div>
    </div>
  )
}

export default GenericToast
