import React, { useState, useEffect, useContext } from "react";
import Button from "../../atoms/button/button";
import Input from "../../atoms/input/input";
import InputFilesZip from "../../atoms/input-files-zip/inputFilesZip";
import Loading from "../../atoms/loading/loading";
import Modal from "../../atoms/modal/modal";
import useBrowserMode from '../../../shared/hooks/useBrowserMode'
import { postInfoUser } from '../../../services/pqrs'
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import GlobalContext from '../../../contexts/globalContext';
import "./formContact.scss";

const FormContact = (props: any) => {

   const arrForm = props;
   const defaultFormValues = {
      personType: "",
      firstName: "",
      lastName: "",
      idNumType: "",
      idNum: "",
      contactUser: "",
      phone: "",
      cellphone: "",
      email: "",
      address: "",
      city: "",
      country: "Colombia",
      anotherCountry: "",
      state: "Antioquia",
      anotherState: "",
      request: "",
      subject: "",
      comOffice: "",
      message: "",
      attachments: []
   }
   const { isBrowser } = useBrowserMode();
   const [formValues, setFormValues] = useState(defaultFormValues);
   const [checkBox, setCheckBox] = useState(false)
   const [baseFilesAuxArr, setBaseFilesAuxArr] = useState<any>(null)
   const [loading, setloading] = useState(false)
   const [ticket, setTicket] = useState<any>(null)
   const [codeType, setCodeType] = useState("");
   const [codeTheme, setCodeTheme] = useState("");
   const [codeTypeDoc, setCodeTypeDoc] = useState("");
   const [errorCatch, seterrorCatch] = useState(false)
   const [isSuccess, setisSuccess] = useState(false)
   const [verify, setVerify] = useState(false)
   const [labelPos, setLabelPos] = useState(0)
   const [userType, setUserType] = useState('')
   const [tooltip, setTooltip] = useState(false)
   const Context = useContext(GlobalContext);
   const updateFormValues = (value: any, name?: any) => {
      name && setFormValues({ ...formValues, [name]: value })
   }

   let zip = require("jszip")()

   useEffect(() => {
      if (formValues.personType === '') {
         setLabelPos(0)
      } else if (formValues.personType === 'Persona') {
         setLabelPos(0)
         setUserType('person')
      } else {
         setLabelPos(1)
         setUserType('company')
      }
      setFormValues({ ...formValues, idNum: '', firstName: '' })
   }, [formValues.personType])

   const exportZip = (list) => {
      if (list && list.length > 0) {
         list.map((element) => {
            zip.file(element.name, element.base64, { base64: true })
         })
         zip.generateAsync({ type: "blob" }).then(function (content) {
            encodeFileBase64(content)
         })
         zip = require("jszip")()
      }
   }
   
   const encodeFileBase64 = (file) => {
      let reader = new FileReader()
      if (file) {
        reader.readAsDataURL(file)
        reader.onload = () => {
          let base64: any = reader.result
          if (base64) {
            setBaseFilesAuxArr([
               {
                  base64: base64.split(",")[1],
                  name: file.name,
                  size: file.size,
              },
            ])
          }
        }
        reader.onerror = (e) => {
          console.log("Error: ", e)
        }
      }
    }
   useEffect(() => {
      exportZip(formValues.attachments)
   }, [formValues.attachments])
   const handleSendMessage = async () => {
      try {
         setloading(true)
         let payload = {
            "CustomerType": formValues.personType === 'Persona' ? 1 : 2,
            "PersonType": formValues.personType,
            "FirstName": formValues.firstName,
            "LastName": formValues.lastName,
            "DocumentType": codeTypeDoc,
            "DocumentNumber": formValues.idNum,
            "Email": formValues.email,
            "Phone": formValues.phone ? formValues.phone : 0,
            "MobilePhone": formValues.cellphone,
            "Address": formValues.address,
            "City": formValues.city,
            "Descripcion_KUT": `${formValues.message}\n\r Contactar: ${formValues.contactUser}\n\r Lugar de la experiencia: ${formValues.comOffice}\n\r País: ${formValues.country.toLocaleLowerCase() === "otro" ? formValues.anotherCountry : formValues.country}\n\r Tipo usuario: ${formValues.personType}\n\r Tipo y número doc: ${formValues.idNumType} - ${formValues.idNum}\n\r ${formValues.personType === 'Persona' ? `Nombre persona: ${formValues.firstName} ${formValues.lastName}` : `Razón social: ${formValues.firstName}\n\r Nombre contacto: ${formValues.lastName}`}`,
            "TipodeCaso_KUT": codeType,
            "DataOriginTypeCode": "4",
            "Fuente1_KUT": "191",
            "IncidentCategoryID": "",
            "TicketName": `Contáctanos - ${formValues.subject}`,
            "ServiceCategoryID": codeTheme,
            "Tiposede_KUT": "",
            "StatusCode": "1",
            "MimeType": baseFilesAuxArr ? "application/zip" : "",
            "AttachmentName": baseFilesAuxArr ? "attachments.zip" : "",
            "Binary": baseFilesAuxArr ? baseFilesAuxArr[0].base64 : ""
         }
         const req = await postInfoUser(payload);
         setTimeout(() => {
            if (req.status === 200) {
               setTicket({
                  case: req.data.data,
                  caseName: formValues.firstName,
                  caseEmail: formValues.email
               })
               setFormValues(defaultFormValues)
               setisSuccess(true)
            } else {
               seterrorCatch(true)
            }
            setloading(false)
         }, 2000);
      } catch (error) {
         setloading(false)
         seterrorCatch(true)
         console.error("ERROR => ", error)
      }
   }
   const handleLable = ( label ) => {
      if (!label.includes(';')) {
         return label
      } else {
         let finalLabel = label.split(';')
         return finalLabel[labelPos]
      }
   }
   const validateForm = () => {
      return (
         formValues.personType === "" || formValues.request === "" || formValues.subject === "" ||
         formValues.firstName === "" || formValues.lastName === "" ||
         formValues.idNumType === "" || formValues.idNum === "" ||
         formValues.contactUser === "" ||
         formValues.cellphone === "" || formValues.email === "" ||
         !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formValues.email) ||
         formValues.address === "" || formValues.city === "" ||
         formValues.comOffice === "" || formValues.message === "" ||
         formValues.country === "" || formValues.state === "" ||
         ((formValues.country.toLocaleLowerCase() === "otro" && formValues.anotherCountry === "") ? true : false) ||
         ((formValues.state.toLocaleLowerCase() === "otro" && formValues.anotherState === "") ? true : false) ||
         !checkBox
      )
   }
   const errorDetected = (_input): boolean => {
      if (_input.nombreId === "personType" && formValues.request.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "firstName" && formValues.firstName.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "lastName" && formValues.lastName.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "idNumType" && formValues.idNumType.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "idNum" && formValues.idNum.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "contactUser" && formValues.contactUser.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "cellphone" && formValues.cellphone.length !== 10 && verify) {
         return true
      }
      if (_input.nombreId === "email" && !/^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/.test(formValues.email) && verify) {
         return true
      }
      if (_input.nombreId === "address" && formValues.address.length === 0 && verify) {
         return true
      }
      if (formValues.country.toLocaleLowerCase() === "otro" && _input.nombreId === "anotherCountry" && formValues.anotherCountry.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "country" && formValues.country.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "state" && formValues.state.length === 0 && verify) {
         return true
      }
      if (formValues.state.toLocaleLowerCase() === "otro" && _input.nombreId === "anotherState" && formValues.anotherState.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "city" && formValues.city.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "request" && formValues.request.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "subject" && formValues.subject.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "comOffice" && formValues.comOffice.length === 0 && verify) {
         return true
      }
      if (_input.nombreId === "message" && formValues.message.length === 0 && verify) {
         return true
      }
      return false
   }

   const getInput = (_input, _i, _length) => {
      switch (_input.tipoCampo) {
         case "file":
            return (
               <div className="input__file__container" key={`input-${_i}`}>
                  <h6 className="input__file__container__h6">{_input.inputPrincipal}</h6>
                  <InputFilesZip
                     key={`inp${_i}`}
                     title={_input.inputPrincipal}
                     nameId={_input.nombreId}
                     value={formValues[_input.nombreId]}
                     handleChange={(e) => updateFormValues(e, _input.nombreId)}
                  />
               </div>
            )
         default:
            return (
               <div className={((_i === 0 && _length > 2 && _length % 2 !== 0) ? `input__container__first` : `input__container`) + ((_input.nombreId === "anotherCountry" && formValues.country.toLocaleLowerCase() !== "otro") ? " hide" : "") + ((_input.nombreId === "anotherState" && formValues.state.toLocaleLowerCase() !== "otro") ? " hide" : "")} key={`input-${_i}`}>
                  <div className="input__container__label">
                     <h6 className="input__container__label__h6">{ handleLable(_input.inputPrincipal) }</h6>
                     { _input.nombreId === 'request' && _input.textoTerminos &&
                        <>
                           <button onMouseOver={() => setTooltip(true)} onMouseOut={() => setTooltip(false)}>
                              <i className="icon-question-circle" />
                           </button>
                           {
                              tooltip &&
                              <div className="input__container__label__tooltip">
                                 { documentToReactComponents(_input.textoTerminos.json) }
                              </div>
                           }
                        </>
                     }
                  </div>
                  <Input
                     key={`inp${_i}`}
                     title={_input.inputPrincipal}
                     type={_input.tipoCampo}
                     nameId={_input.nombreId}
                     value={formValues[_input.nombreId]}
                     handleChange={(e) => updateFormValues(e, _input.nombreId)}
                     isError={errorDetected(_input)}
                     errorMessage={_input.mensajeError}
                     editable={ true }
                     options={_input.listaSelect || null}
                     placeholder={_input.tipoCampo === "select" ? _input.nombreId === "state" ? _input.listaSelect[0] : _input.nombreId === "country" ? _input.listaSelect[0] : "" : ""}
                     setCodeType={setCodeType}
                     setCodeTheme={setCodeTheme}
                     setCodeTypeDoc={setCodeTypeDoc}
                     gigyaAccount={Context.gigyaContext.gigyaAccount}
                     person={userType}
                  />
               </div>
            )
      }
   }

   useEffect(() => {
      if (isBrowser && Context.gigyaContext.gigyaAccount) {
         setFormValues({
            ...formValues, firstName: Context.gigyaContext.gigyaAccount.profile.firstName, lastName: Context.gigyaContext.gigyaAccount.profile.lastName, email: Context.gigyaContext.gigyaAccount.profile.email,
            idNum: Context.gigyaContext.gigyaAccount.data.docNumber, phone: Context.gigyaContext.gigyaAccount.data.phone, cellphone: Context.gigyaContext.gigyaAccount.data.mobile
         })
      } else {
         setFormValues(defaultFormValues)
      }
   }, [Context.gigyaContext.gigyaAccount])

   useEffect(() => {
      let idNumber = document.getElementById('cellphone');

      idNumber?.addEventListener('change', function (e) {
         if (!e.target) return;
         let value = e.target.value;
         let initialValue;
         if (value[0] == 0) {
            e.target.value = initialValue;
         }
      })

   }, [formValues.cellphone])
   useEffect(() => {
      let idNumber = document.getElementById('idNum');

      idNumber?.addEventListener('change', function (e) {
         let value = e.target.value;
         let initialValue;
         if (value[0] == 0) {
            e.target.value = initialValue;
         }
      })

   }, [formValues.idNum])
   const handleFinish = (result) => {
      if (result === 'success') {
         setisSuccess(false)
      } else {
         seterrorCatch(false)
      }
      location.reload()
   }
   return (
      <>
         <div className="o-form">
            {/* -------------------------------------------- */}
            <div id="group0" className="o-form__group--open">
               {
                  arrForm[0].tituloGeneral &&
                  <span className="o-form__person-title">{ arrForm[0].tituloGeneral }</span>
               }
               <div className="o-form__group-cont">
                  {arrForm && arrForm[0].referenciaFormulario.map((inp, i) => (
                     getInput(inp, i, arrForm[0].referenciaFormulario.length)
                  ))}
               </div>
            </div>
            {/* -------------------------------------------- */}
            <div id="group1" className="o-form__group--open">
               {
                  arrForm[1].tituloGeneral &&
                  <span className="o-form__person-title">{ arrForm[1].tituloGeneral }</span>
               }
               <div className="o-form__group-cont">
                  {arrForm && arrForm[1].referenciaFormulario.map((inp, i) => (
                     getInput(inp, i + arrForm[0].referenciaFormulario.length, arrForm[1].referenciaFormulario.length)
                  ))}
               </div>
            </div>
            {/* -------------------------------------------- */}
            <div id="group2" className="o-form__group--open">
               {
                  arrForm[2].tituloGeneral &&
                  <span className="o-form__person-title">{ arrForm[2].tituloGeneral }</span>
               }
               <div className="o-form__group-cont">
                  {arrForm && arrForm[2].referenciaFormulario.map((inp, i) => (
                     getInput(inp, i + arrForm[0].referenciaFormulario.length + arrForm[1].referenciaFormulario.length, arrForm[2].referenciaFormulario.length)
                  ))}
               </div>
            </div>
            {/* -------------------------------------------- */}
            <div id="group3" className="o-form__group--open">
               {
                  arrForm[3].tituloGeneral &&
                  <span className="o-form__person-title">{ arrForm[3].tituloGeneral }</span>
               }
               <div className="o-form__group-cont">
                  {arrForm && arrForm[3].referenciaFormulario.map((inp, i) => (
                     inp.tipoCampo.includes("file") ? (
                        <InputFilesZip
                           key={i}
                           title={inp.inputPrincipal}
                           nameId={inp.nombreId}
                           value={formValues[inp.nombreId]}
                           handleChange={updateFormValues}
                        />
                     ) : (
                        getInput(inp, i + arrForm[0].referenciaFormulario.length + + arrForm[1].referenciaFormulario.length + + arrForm[2].referenciaFormulario.length, arrForm[3].referenciaFormulario.length)
                     )
                  ))}
               </div>
            </div>
            {/* -------------------------------------------- */}
            <div className="o-form__bottom">
               <div className="o-form__bottom-txt">
                  {(arrForm && arrForm[4].referenciaFormulario[0].textoTerminos.json.content) && documentToReactComponents(arrForm[4].referenciaFormulario[0].textoTerminos.json)}
               </div>
               <div className="o-form__bottom-btns">
                  <div className="o-form__bottom-check">
                     <input type="checkbox" id="cbox2" onClick={() => setVerify(true)} value="second_checkbox" onChange={(e) => setCheckBox(e.target.checked)} />
                     <label htmlFor="cbox2" onClick={() => setVerify(true)}> {arrForm && arrForm[4].referenciaFormulario[0].inputPrincipal} </label>
                  </div>
                  <div className="o-form__bottom-btn">
                     {loading ?
                        <>
                           <Loading />
                           Estamos procesando tu petición...
                        </>
                        :
                        <Button
                           id={"btn-enviar"}
                           type={"button"}
                           classname={`secondary `}
                           text={arrForm[4].referenciaFormulario[0].tituloBotonTerminos || "Send"}
                           isDisabled={validateForm()}
                           gtmMark={"gtmMark"}
                           onClickAction={() => handleSendMessage()}
                        />
                     }
                  </div>
               </div>
            </div>
            {/* -------------------------------------------- */}
         </div>
         {errorCatch && <Modal open={true}>
            <div className="o-form-success-zip">
               <div className="o-form-success-zip__content">
                  <i className="icon__check icon-close" />
                  <h3 className="o-form-success-zip__content__message">{arrForm[5].tituloGeneral}</h3>
                  <p className="o-form-success-zip__content__info">{arrForm[5].tituloBoton}</p>
                  <a href="" onClick={ () => handleFinish('failure') } className="o-form-success-zip__content__btn" >ACEPTAR</a>
               </div>
            </div>
         </Modal>}
         {isSuccess && <Modal open={true}>
            <div className="o-form-success-zip">
               <div className="o-form-success-zip__content">
                  <i className="icon__check icon-check-circle1" />
                  <h3 className="o-form-success-zip__content__message">¡{ticket.caseName}, {arrForm[6].tituloGeneral}</h3>
                  <p className="o-form-success-zip__content__info">{arrForm[6].tituloBoton} {ticket.case}, {arrForm[6].redireccionBoton} {ticket.caseEmail}.</p>
                  <button onClick={ () => handleFinish('success') } className="o-form-success-zip__content__btn">Aceptar</button>
               </div>
            </div>
         </Modal>}
      </>
   );
};
export default FormContact;